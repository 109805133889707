import {
  ActionButton,
  FeedbackOperationEnum,
  FormErrors,
  ValidationError,
  AppointmentOriginEnum,
  i18n,
  copy
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Rating from '@material-ui/lab/Rating'
import React, { FocusEvent, useRef } from 'react'

import { Field, FieldProps, FormikHelpers } from 'formik'
import { notificationService } from '../../../injectables'
import { submitFeedback } from '../../../requests'
import { UIState } from '../../../styles'
import { FeedbackValues } from './FeedbackModal'
import mixpanel from 'mixpanel-browser'
import { currentPage, getWorkflow, getWorkflowTeam, currentWorkflowPage } from '../../../utils/mixpanel'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'

const useStyles = makeStyles<Theme, FeedbackFormProps>({
  root: {
    width: '100%',
    margin: 0
  },
  rating: {
    color: 'rgb(0, 114, 240)'
  },
  ratingError: {
    borderRadius: 4,
    border: 'solid 1px #EB0042'
  },
  emptyRating: {
    color: 'rgb(0, 114, 240)'
  },
  feedbackLabel: {
    fontWeight: 600,
    fontSize: '16px',
    marginTop: '16px',
    marginBottom: '8px',
    textAlign: 'center'
  },
  questionLabel: {
    marginBottom: '9px',
    textAlign: 'center'
  },
  textBox: {
    width: '100%',
    marginBottom: '13px'
  },
  textBoxError: {
    border: 'solid 1px #EB0042',
    backgroundColor: '#ffffff'
  },
  textFieldInput: ({ textValue }) => ({
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: textValue ? '#44444D' : '#75757B',
    paddingLeft: 16,
    paddingTop: 8,
    '&::placeholder': {
      opacity: 1
    }
  }),
  rootTextBox: {
    borderRadius: 4,
    backgroundColor: '#ffffff',
    border: 'solid 1px #75757B',
    '&:hover': {
      border: 'solid 1px #63666A'
    }
  },
  activeTextBox: {
    borderRadius: 4,
    border: 'solid 1px #0072F0 !important'
  },
  submitButton: ({ buttonVariant }) => ({
    color: buttonVariant === 'contained' ? '#FFFFFF' : 'rgb(0, 114, 240)',
    marginBottom: '8px',
    marginTop: '16px',
    width: '65%'
  })
})

export interface FeedbackFormProps {
  isSubmitting: boolean
  ratingValue: number
  textValue: string
  buttonVariant?: 'text' | 'contained'
  placeholder?: string
  validateOnBlur?: boolean
  validate?: {
    rating?: (value: string) => string | undefined
    feedbackComments?: (value: string) => string | undefined
  }
  onChangeRating?: (newValue: number) => void
  onChangeText?: (newValue: string) => void
}

export const FeedbackForm = (props: FeedbackFormProps) => {
  const ratingRef = useRef<HTMLElement>(null)
  const {
    buttonVariant = 'text',
    isSubmitting,
    placeholder = 'Type your review...',
    ratingValue,
    textValue,
    validateOnBlur,
    validate = {},
    onChangeRating = () => {
      // noop
    },
    onChangeText = () => {
      // noop
    }
  } = props
  const classes = useStyles(props)

  return (
    <React.Fragment>
      <div data-testid='feedback-rating' className={classes.root}>
        <Grid container alignItems='center' spacing={1} direction='column'>
          <Grid item>
            <Typography className={classes.feedbackLabel} variant='body2' component='h2'>
              {i18n.__(copy.Feedback.Label)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.questionLabel} variant='body2'>
              {i18n.__(copy.Feedback.QuestionLabel)}
            </Typography>
          </Grid>
          <Grid item>
            <Field name='feedbackRating' validate={validate.rating}>
              {({ field, form, meta }: FieldProps) => (
                <>
                  <Rating
                    ref={ratingRef}
                    data-testid='feedback-rating-stars'
                    className={`${classes.rating} ${!!meta.error && !!meta.touched ? classes.ratingError : ''}`}
                    name='feedback-rating'
                    emptyIcon={<StarBorderIcon fontSize='inherit' className={classes.emptyRating} />}
                    value={ratingValue}
                    onChange={(event: unknown, newValue: number | null) => {
                      form.setFieldValue(field.name, newValue)
                      onChangeRating(newValue!)
                    }}
                    onBlur={(event: FocusEvent) => {
                      const buttonGroup = event.currentTarget
                      const elementReceivingFocus = event.relatedTarget

                      if (validateOnBlur && !buttonGroup.contains(elementReceivingFocus as Node)) {
                        form.setFieldTouched(field.name)
                      }
                    }}
                    tabIndex={0}
                    aria-label='feedback-rating-stars'
                    aria-required='true'
                    aria-describedby={`input-${field.name}-error`}
                    aria-invalid={!!form.errors[field.name] && !!meta.touched}
                  />
                  <ValidationError
                    name={field.name}
                    errMsg={meta.error}
                    touched={meta.touched}
                    element={ratingRef}
                    formErrors={form.errors as FormErrors}
                  />
                </>
              )}
            </Field>
          </Grid>
          <Grid item style={{ width: '80%' }}>
            <Field name='feedbackComments' validate={validate.feedbackComments}>
              {({ field, form, meta }: FieldProps) => (
                <>
                  <TextField
                    data-testid='feedback-text-input'
                    className={`${classes.textBox} ${!!meta.error && !!meta.touched ? classes.textBoxError : ''}`}
                    placeholder={placeholder}
                    value={textValue}
                    onChange={(event: React.ChangeEvent<{ value: string }>) => {
                      form.setFieldValue(field.name, event.target.value)
                      onChangeText(event.target.value)
                    }}
                    onBlur={() => {
                      if (validateOnBlur) {
                        form.setFieldTouched(field.name)
                      }
                    }}
                    fullWidth
                    multiline
                    minRows={4}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldInput,
                        root: classes.rootTextBox,
                        focused: classes.activeTextBox
                      }
                    }}
                    inputProps={{
                      'data-testid': 'content-feedback-text-input',
                      'aria-label': 'feedback-text-input',
                      'aria-describedby': `input-${field.name}-error`,
                      'aria-invalid': !!form.errors[field.name] && !!meta.touched,
                      maxLength: 1000
                    }}
                  />
                  <ValidationError
                    name={field.name}
                    errMsg={meta.error}
                    touched={meta.touched}
                    element={ratingRef}
                    formErrors={form.errors as FormErrors}
                  />
                </>
              )}
            </Field>
          </Grid>
          <Grid item container justifyContent='center'>
            <ActionButton
              className={classes.submitButton}
              testid='feedback-submit-button'
              color='primary'
              variant={buttonVariant}
              buttonText={i18n.__(copy.Buttons.SubmitFeedback)}
              type='submit'
              disabled={isSubmitting}
              submitting={isSubmitting}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export const handleSubmit = (
  values: FeedbackValues,
  actions: FormikHelpers<FeedbackValues>,
  feedbackRating: number,
  feedbackComments: string,
  operation: FeedbackOperationEnum,
  setFeedbackSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
  bookingStore: string,
  origin: AppointmentOriginEnum | undefined,
  bookingId?: string
): void => {
  actions.setSubmitting(true)
  mixpanel.track(MixpanelEvents.SubmitFeedback, {
    page: currentPage(),
    workflow: getWorkflow(origin),
    workflowTeam: getWorkflowTeam(origin),
    workflowPage: currentWorkflowPage(),
    rating: feedbackRating
  })

  submitFeedback(feedbackComments, feedbackRating!, operation, bookingStore, bookingId)
    .then(response => {
      actions.setSubmitting(false)
      if (response.status === 201) {
        setFeedbackSubmitted(true)
      }
    })
    .catch(() => {
      notificationService.createNotification('Something went wrong', UIState.Error)
      actions.setSubmitting(false)
    })
}
