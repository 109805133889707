import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { useRef, useState, FunctionComponent } from 'react'
import mixpanel from 'mixpanel-browser'
import { copy, i18n, FeedbackOperationEnum } from '@atbdigitalteam/obs-shared-components'

import { currentPage, getWorkflow, getWorkflowTeam, currentWorkflowPage } from '../../../../src/utils/mixpanel'
import { useBookingStore } from '../../../injectables/stores/BookingStore'
import { submitFeedback } from '../../../requests'
import { FeedbackForm, FeedbackSubmitted } from './'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      marginTop: 0,
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#44444D'
    },
    heading: {
      fontWeight: 'bold',
      fontSize: '18px',
      top: theme.spacing(1)
    },
    paper: {
      width: '100%'
    },
    dividers: {
      borderBottom: 'none'
    }
  })
)

export interface FeedbackValues {
  feedbackRating: number
  feedbackComments: string
}

export interface FeedbackModalProps {
  open: boolean
  onClose: () => void
}

export const FeedbackModal: FunctionComponent<FeedbackModalProps> = ({ open, onClose }) => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const { closeButton, dividers, heading, paper } = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { origin, bookingStoreToString } = useBookingStore()

  const handleClose = () => {
    setSubmitted(false)
    onClose()
  }

  const handleSubmit = async (values: FeedbackValues, actions: FormikHelpers<FeedbackValues>): Promise<void> => {
    mixpanel.track(MixpanelEvents.SubmitFeedback, {
      page: currentPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin),
      workflowPage: currentWorkflowPage(),
      rating: values.feedbackRating
    })

    actions.setSubmitting(true)

    const pathname = window.location?.pathname?.replace('/', '')

    const operation =
      pathname === FeedbackOperationEnum.MODIFY || pathname === FeedbackOperationEnum.CANCEL
        ? (pathname as FeedbackOperationEnum)
        : FeedbackOperationEnum.INCOMPLETE

    const bookingStore = bookingStoreToString()

    try {
      await submitFeedback(values.feedbackComments, values.feedbackRating, operation, bookingStore)
      setSubmitted(true)
      actions.resetForm()

      if (closeButtonRef.current) closeButtonRef.current.focus()
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{
        feedbackRating: 0,
        feedbackComments: ''
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          maxWidth='sm'
          data-testid='feedback-modal'
          PaperProps={{
            'aria-label': 'send us your feedback',
            className: paper
          }}
        >
          <DialogTitle>
            <Typography variant='body1' className={heading} data-testid='feedback-modal-title'>
              Feedback
            </Typography>
          </DialogTitle>
          <DialogContent dividers className={dividers}>
            <Form>
              {!submitted && (
                <FeedbackForm
                  buttonVariant='contained'
                  isSubmitting={isSubmitting}
                  ratingValue={values.feedbackRating}
                  textValue={values.feedbackComments}
                  validate={{
                    rating: value => required(value, i18n.__(copy.Validation.required.feedbackRating)),
                    feedbackComments: value => sensitiveInfo(value)
                  }}
                  validateOnBlur
                />
              )}
              {submitted && <FeedbackSubmitted />}
            </Form>
          </DialogContent>
          <DialogActions>
            <IconButton
              ref={closeButtonRef}
              data-testid='close-button'
              aria-label='close feedback'
              className={closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
