import { i18n, copy, ActionButton } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import SvgIcon from '@material-ui/core/SvgIcon'
import { navigate, RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

import { useAuthentication, useFeatureStore } from '../../../injectables'
import { routes } from '../../../routing/routes'
import { Footer } from './Footer'
import { observer } from 'mobx-react'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'

export interface LandingPageProps extends RouteComponentProps {
  branchId: string
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    maxWidth: '420px',
    minWidth: '225px',
    padding: '79px 46px 24px 46px',
    margin: 'auto',

    '@media (min-width: 481px)': {
      marginTop: '83px'
    }
  },
  item: {
    marginTop: '16px',
    marginBottom: '16px'
  },
  center: {
    margin: 'auto'
  },
  atbIcon: {
    backgroundImage: 'url(https://grow-static-content.poweredbygrow.com/atb/rebrand/ATBLogo/ATB_Blue_RGB.svg)',
    width: '83.276px',
    height: '58px',
    backgroundSize: '83.276px 58px'
  },
  logo: {
    justifySelf: 'center',
    alignSelf: 'center'
  },
  title: {
    fontFamily: 'ATB TT Norms',
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '32px'
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400
  },
  divider: {
    border: 'solid 1px #97CFFF',
    width: '100%',
    marginTop: '34.5px',
    marginBottom: '11.5px'
  },
  label: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    marginTop: '75px',
    marginBottom: '9px'
  }
})

export const BaseLandingPage = ({ branchId }: LandingPageProps) => {
  const classes = useStyles()
  const { disableAuthRedirect } = useFeatureStore()
  const { redirectToBusinessLogin, redirectToPersonalLogin, reset } = useAuthentication(branchId)

  useEffect(() => {
    reset()
    mixpanel.track(MixpanelEvents.LoginDisplayed)
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty('background-color', '#EDF5FE')
    if (disableAuthRedirect) {
      navigate(`${routes.details.path}${branchId}`)
    }

    return () => {
      document.documentElement.style.setProperty('background-color', '#FFFFFF')
    }
  }, [disableAuthRedirect])

  const handleClickPersonal = () => {
    mixpanel.track(MixpanelEvents.LoginPersonal)
    redirectToPersonalLogin()
  }

  const handleClickBusiness = () => {
    mixpanel.track(MixpanelEvents.LoginBusiness)
    redirectToBusinessLogin()
  }

  const handleClickGuest = () => {
    mixpanel.track(MixpanelEvents.LoginGuest)
    navigate(`${routes.details.path}${branchId}`).finally(() => {
      window.scrollTo(0, 0)
    })
  }

  return (
    <Grid
      data-testid='landing-page'
      container
      direction='column'
      justifyContent='center'
      spacing={2}
      className={classes.root}
    >
      <Grid className={`${classes.item} ${classes.center}`}>
        <Link
          aria-label='ATB logo redirecting to www.atb.com'
          data-testid='atb-logo'
          href='https://www.atb.com/'
          className={classes.logo}
        >
          <SvgIcon className={classes.atbIcon} />
        </Link>
      </Grid>
      <div className={classes.divider} />
      <Grid className={`${classes.item} ${classes.center}`}>
        <Typography variant='h1' className={classes.title}>
          {i18n.__(copy.Book.LittleTitle)}
        </Typography>
      </Grid>
      <Grid className={classes.item}>
        <Typography variant='caption' className={classes.text}>
          {i18n.__(copy.Auth.Intro)}
        </Typography>
      </Grid>
      <Grid className={classes.item}>
        <ActionButton
          testid='personal-login'
          type='button'
          buttonText={i18n.__(copy.Buttons.LoginPersonal)}
          onClick={handleClickPersonal}
        />
      </Grid>
      <Grid className={classes.item}>
        <ActionButton
          testid='business-login'
          type='button'
          buttonText={i18n.__(copy.Buttons.LoginBusiness)}
          onClick={handleClickBusiness}
        />
      </Grid>
      <Grid className={classes.item}>
        <Typography variant='caption' component='div' className={`${classes.text} ${classes.label}`}>
          {i18n.__(copy.Auth.GuestIntro)}
        </Typography>
        <ActionButton
          testid='guest-login'
          type='button'
          variant='outlined'
          buttonText={i18n.__(copy.Buttons.LoginGuest)}
          onClick={handleClickGuest}
        />
      </Grid>
      <Grid className={`${classes.text} ${classes.center}`}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export const LandingPage = observer(BaseLandingPage)
