import { debounceTime, Advisor, AppointmentOriginEnum, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { navigate, RouteComponentProps } from '@reach/router'
import { AxiosError, AxiosResponse } from 'axios'
import mixpanel from 'mixpanel-browser'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { FormikHelpers } from 'formik'

import { fetchAvailability, fetchAvailableDates, notificationService, useBookingStore } from '../../../injectables'
import { confirmAppointment, deleteEventByBookingId, holdTimeslot, modifyAppointment } from '../../../requests'
import { UIState } from '../../../styles'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { detectBookingIdStyle, parseEmail, BookingIdStyleEnum } from '../../../utils/bookingId'
import { useDebouncedEffect } from '../../../utils/hooks'
import {
  convertClientType,
  secondsSinceTime,
  convertLoanAmount,
  currentWorkflowPage,
  currentPage,
  getWorkflow,
  getWorkflowTeam
} from '../../../utils/mixpanel'
import { OBSLiteOrigins } from '../../../utils/constants'
import { BookingDetailsForm } from '../../components/BookingDetailsForm/BookingDetailsForm'
import { BookingInfo } from '../../components/BookingInfo/BookingInfo'
import { Feedback } from '../../components/Feedback'
import { GwsDetailsForm } from '../../components/GwsDetailsForm'
import { ProsperDetailsForm } from '../../components/ProsperDetailsForm/ProsperDetailsForm'
import { InactiveBranchModifyComponent } from './InactiveBranchModifyComponent'
import { ModifyFeedbackComponent } from './ModifyFeedbackComponent'
import { DetailsFormValues } from '../../../types'
import { ObsLiteForm } from '../../components/ObsLiteForm'

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  }
})

const BaseModifyPage = (_props: RouteComponentProps) => {
  const classes = useStyles()
  const [step, setStep] = useState(1)
  const [startTime, setStartTime] = useState(new Date())
  const branchActive = useRef<boolean | undefined>(undefined)

  const {
    advisor,
    branch,
    date,
    meetingMethod,
    meetingMethodCategory,
    appointmentType,
    proficiency,
    timeSlot,
    bookingId,
    serviceSubType,
    email,
    employerName,
    province,
    preferredContactMethod,
    firstName,
    lastName,
    branchAddress,
    description,
    businessName,
    phoneNumber,
    existingCustomer,
    caslConsent,
    eventModified,
    bpId,
    eventId,
    reminderRequested,
    origin,
    loginType,
    caseId,
    loanAmount,
    setEventId,
    setBranch,
    setAdvisor,
    setBookingId,
    setMeetingLink
  } = useBookingStore()

  useDebouncedEffect(
    () => {
      if (step === 2) fetchAvailability()
    },
    debounceTime,
    [branch, date, meetingMethodCategory, appointmentType, serviceSubType, proficiency, advisor, step, loanAmount]
  )

  useEffect(() => {
    if (bookingId && bookingId.length > 6) {
      fetchAvailableDates()
    }
  }, [branch, appointmentType, serviceSubType, proficiency, advisor, meetingMethodCategory, bookingId])

  const createTakenTimeSlotNotification = () => {
    notificationService.createNotification(
      'Cannot proceed. Looks like someone has just taken your selected time slot',
      UIState.Info
    )
  }

  const createErrorNotification = () => {
    notificationService.createNotification('Something went wrong', UIState.Error)
  }

  const handleResponse = (
    actions: { setSubmitting: (arg0: boolean) => void },
    response: { status: number; data: { hangoutLink?: string } }
  ) => {
    actions.setSubmitting(false)
    if (response.status === 201) {
      setMeetingLink(response.data.hangoutLink)
      setStep(step + 1)
    } else {
      createErrorNotification()
    }
  }

  const handleConfirmAppointment = async (holdEventId: string, holdAdvisor: Advisor): Promise<AxiosResponse> => {
    mixpanel.track(MixpanelEvents.DetailsPageNext, {
      clientType: convertClientType(appointmentType),
      appointmentSubtype: serviceSubType,
      appointmentMethod: meetingMethod,
      creditRequested: convertLoanAmount(loanAmount),
      page: 'Modify',
      workflowPage: currentWorkflowPage(),
      pageTime: secondsSinceTime(startTime)
    })
    return confirmAppointment(
      branch?.transit!,
      holdEventId,
      appointmentType!,
      serviceSubType!,
      email!,
      employerName!,
      province!,
      firstName!,
      lastName!,
      branchAddress,
      bookingId!,
      description!,
      businessName!,
      phoneNumber!,
      caslConsent,
      preferredContactMethod!,
      reminderRequested,
      true,
      existingCustomer,
      meetingMethod,
      holdAdvisor,
      bpId,
      loginType,
      origin,
      caseId,
      loanAmount
    )
  }

  const handleSubmitUnmodified = async (
    values: DetailsFormValues,
    actions: FormikHelpers<DetailsFormValues>
  ): Promise<void> => {
    const response = await modifyAppointment(
      appointmentType!,
      email!,
      description!,
      eventId!,
      serviceSubType!,
      branch?.transit!,
      reminderRequested,
      meetingMethod,
      loanAmount,
      province,
      existingCustomer
    )
    handleResponse(actions, response)
  }

  const handleSubmitModified = async (
    values: DetailsFormValues,
    actions: FormikHelpers<DetailsFormValues>
  ): Promise<void> => {
    const holdResponse = await holdTimeslot(
      branch?.transit!,
      date!,
      timeSlot?.time!,
      timeSlot?.type!,
      appointmentType!,
      serviceSubType!,
      proficiency!,
      meetingMethod,
      bookingId!,
      advisor?.email,
      origin
    )

    if (holdResponse.status === 204) {
      actions.setSubmitting(false)
      createTakenTimeSlotNotification()
    } else if (holdResponse.status === 201) {
      const confirmResponse = await handleConfirmAppointment(holdResponse.data.eventId, holdResponse.data.advisor)

      if (confirmResponse.status === 201) {
        await deleteEventByBookingId(eventId!, bookingId!, email!, false)
      }

      handleResponse(actions, confirmResponse)
      setEventId(holdResponse.data.eventId)
      setAdvisor(holdResponse.data.advisor)
    }
  }

  const handleSubmit = async (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>): Promise<void> => {
    try {
      if (!eventModified) {
        await handleSubmitUnmodified(values, actions)
      } else {
        await handleSubmitModified(values, actions)
      }
    } catch (err) {
      actions.setSubmitting(false)
      if ((err as AxiosError)?.response?.status === 400) {
        createTakenTimeSlotNotification()
      } else {
        createErrorNotification()
      }
    }
  }

  const cancelAndRedirect = async (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>) => {
    deleteEventByBookingId(eventId!, bookingId!, email!, true)
      .then(() => {
        actions.setSubmitting(false)
        mixpanel.track(MixpanelEvents.CancelAppointment, {
          cancelSuccess: 'Yes',
          page: currentPage(),
          workflowPage: currentWorkflowPage(),
          workflow: getWorkflow(origin),
          workflowTeam: getWorkflowTeam(origin)
        })
        setEventId(undefined)
        setBranch(null)
        navigate('/book')
      })
      .catch(() => {
        actions.setSubmitting(false)
        mixpanel.track(MixpanelEvents.CancelAppointment, {
          cancelSuccess: 'No',
          page: currentPage(),
          workflowPage: currentWorkflowPage(),
          workflow: getWorkflow(origin),
          workflowTeam: getWorkflowTeam(origin)
        })
        notificationService.createNotification('Something went wrong', UIState.Error)
      })
  }

  const onValidateSuccess = () => {
    if (bookingId && detectBookingIdStyle(bookingId) === BookingIdStyleEnum.NEW_SHORT) {
      setBookingId(`${parseEmail(email)}+${bookingId}`)
    }

    setStep(step + 1)
    setStartTime(new Date())
  }

  useEffect(() => window.scrollTo(0, 0), [step])

  useEffect(() => {
    if (branch) branchActive.current = branch?.active
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch?.active])

  const bigTitle = i18n.__(copy.Book.BigTitle)
  const littleTitle = i18n.__(copy.Book.LittleTitle)
  const submitButtonText = i18n.__(copy.Buttons.Save)

  const renderModifyForm = () => {
    if (origin === AppointmentOriginEnum.PROSPER) {
      return (
        <ProsperDetailsForm
          bigTitle={bigTitle}
          littleTitle={littleTitle}
          submitButtonText={submitButtonText}
          handleSubmit={handleSubmit}
        />
      )
    } else if (origin === AppointmentOriginEnum.GWS) {
      return (
        <GwsDetailsForm
          bigTitle={bigTitle}
          littleTitle={littleTitle}
          submitButtonText={submitButtonText}
          handleSubmit={handleSubmit}
        />
      )
    } else if (OBSLiteOrigins.includes(origin)) {
      return (
        <ObsLiteForm
          bigTitle={bigTitle}
          littleTitle={littleTitle}
          submitButtonText={submitButtonText}
          handleSubmit={handleSubmit}
        />
      )
    } else if (origin === AppointmentOriginEnum.EFS) {
      return !branch || branchActive.current ? (
        <BookingDetailsForm
          branchId=''
          bigTitle={i18n.__(copy.Modify.BigTitle)}
          littleTitle={i18n.__(copy.Modify.LittleTitle)}
          submitButtonText={submitButtonText}
          handleSubmit={handleSubmit}
        />
      ) : (
        <InactiveBranchModifyComponent handleSubmit={cancelAndRedirect} />
      )
    }

    return null
  }

  return (
    <div>
      {(step === 1 || step === 2) && <Feedback />}
      <Grid data-testid='modify-page' container justifyContent='center' alignItems='center' direction='column'>
        <Grid container direction='column' spacing={1} className={classes.formRoot}>
          {step === 1 && <BookingInfo onSuccess={() => onValidateSuccess()} />}
          {step === 2 && renderModifyForm()}
          {step === 3 && <ModifyFeedbackComponent />}
        </Grid>
      </Grid>
    </div>
  )
}

export const ModifyPage = observer(BaseModifyPage)
