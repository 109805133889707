export enum MixpanelEvents {
  DetailsPageLand = 'OABT - Details - Landing',
  DetailsPageNext = 'OABT - Details - on Next',
  FindALocationPageLand = 'OABT - Find A Location Page - Landing',
  FindALocationPageBack = 'OABT - Find A Location Page - on Back',
  FindALocationPageNext = 'OABT - Find A Location Page - on Next',
  FindALocationPageShowMore = 'OABT - Find A Location Page - Show more clicked',
  ShowAvailableBranchesClicked = 'OABT - Find A Location Page - Show available branches',
  FindATimePageLand = 'OABT - Find A Time Page - Landing',
  FindATimePageBack = 'OABT - Find A Time Page - on Back',
  FindATimePageNext = 'OABT - Find A Time Page - on Next',
  FindATimePageBranchChange = 'OABT - Find A Time Page - Change Branch',
  FindATimePageSubtypeChange = 'OABT - Find a Time Page - Appointment Subtype',
  FindATimePageApptMethodChange = 'OABT - Find a Time Page - Appointment Method',
  HelpIcon = 'OABT - Help',
  HelpPhone = 'OABT - Help - Phone',
  HelpContactUs = 'OABT - Help - Contact us page',
  ATBLogo = 'OABT - ATB Logo',
  SendFeedback = 'OABT - Send us feedback',
  SubmitFeedback = 'OABT - Submit feedback',
  SuggestDigitalOptions = 'OABT - Suggest Digital Options',
  SummaryPageLand = 'OABT - Summary Page - Landing',
  SummaryPageConfirm = 'OABT - Summary Page - on Confirmation',
  SummaryPageEdit = 'OABT - Summary Page - Edit',
  ConfirmationPageLand = 'OABT - Confirmation Page - Landing',
  ConfirmatonPageBookAnother = 'OABT - Confirmation Page - Book another appointment',
  ConfirmationPageReturnToATB = 'OABT - Confirmation Page - Return to atb.com',
  ConfirmationPageClickModify = 'OABT - Confirmation Page - Modify appointment',
  ConfirmationPageClickCancel = 'OABT - Confirmation Page - Cancel appointment',
  ValidateModifyOrCancel = 'OABT - Validate',
  CancelAppointment = 'OABT - Cancel - Cancel Button',
  CancelPageClickModify = 'OABT - Cancel - Modify Booking',
  ProsperPageLand = 'OABT - Prosper - Landing',
  ProsperPageNext = 'OABT - Prosper - On Next',
  GwsPageLand = 'OABT - GWS - Landing',
  GwsPageNext = 'OABT - GWS - On Next',
  OBSLitePageLand = 'OABT - OBS Lite - Landing',
  OBSLitePageNext = 'OABT - OBS Lite - On Next',
  OBSLiteBadLink = 'OABT - Bad Link Error',
  OBSLiteBadLinkContactUs = 'OABT - Bad Link Error - Contact Us',
  OBSLiteWrongWorkflow = 'OABT - Wrong Workflow Error',
  OBSLiteWrongWorkflowClick = 'OABT - Wrong Workflow Error - Book An Appointment',
  ExpiredModalDisplayed = 'OABT - Expired - Modal Displayed',
  ExpiredModalButtonClicked = 'OABT - Expired - Modal Button Click',
  LoginBusiness = 'OABT - Login - Business',
  LoginDisplayed = 'OABT - Login - Landing Page Displayed',
  LoginGuest = 'OABT - Login - Guest',
  LoginPersonal = 'OABT - Login - Personal',
  LoginSuccess = 'Login Landing Page - Success'
}
