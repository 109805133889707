import {
  AppointmentOriginEnum,
  AppointmentSubTypeEnum,
  AppointmentTypeEnum,
  i18n,
  copy,
  debounceTime,
  ServiceSubTypes
} from '@atbdigitalteam/obs-shared-components'
import { navigate, useLocation, RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react'
import { parse } from 'query-string'
import React, { useEffect, useRef } from 'react'
import moment from 'moment-timezone'
import { FormikHelpers } from 'formik'

import { Feedback } from '../../components/Feedback'

import { holdTimeslot } from '../../../requests/calendars'
import { UIState } from '../../../styles'

import {
  fetchAvailability,
  fetchAvailableDates,
  notificationService,
  releaseTimeslot,
  useBookingStore,
  bookingStore,
  useCalendarStore
} from '../../../injectables'

import mixpanel from 'mixpanel-browser'
import { convertBoolean, currentPage, secondsSinceTime, timeRange } from '../../../utils/mixpanel'
import { ALBERTA_TIMEZONE } from '../../../utils/constants'
import { getBranch } from '../../../requests/branches'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { GwsDetailsForm } from '../../components/GwsDetailsForm'
import { DetailsFormValues } from '../../../types'
import { useDebouncedEffect } from '../../../utils/hooks'

export const BaseGwsPage = (_props: RouteComponentProps) => {
  const startTime = useRef<Date>(new Date())

  const {
    advisor,
    appointmentType,
    branch,
    date,
    eventId,
    meetingMethod,
    meetingMethodCategory,
    serviceSubType,
    proficiency,
    timeSlot,
    caseId,
    province,
    existingCustomer,
    setBookingId,
    setEventId,
    setAdvisor,
    setCaseId,
    setBranch,
    setAppointmentType,
    setOrigin,
    setServiceSubType
  } = useBookingStore()

  const { availableSlots, availableDates, setAvailableSlots, setAvailableDates } = useCalendarStore()

  const gwsTransitNumber = '401'
  const location = useLocation()
  setOrigin(AppointmentOriginEnum.GWS)

  useEffect(() => {
    if (eventId) {
      setAvailableDates(undefined)
      setAvailableSlots(undefined)
      releaseTimeslot().finally(() => {
        setAdvisor(undefined)
      })
    }

    getBranch(gwsTransitNumber).then(retrievedBranch => {
      setBranch(retrievedBranch)
    })
    setAppointmentType(AppointmentTypeEnum.PERSONAL)

    setServiceSubType(AppointmentSubTypeEnum.GROUP_WEALTH)

    const caseIdVar = parse(location.search).caseId
    setCaseId(Array.isArray(caseIdVar) ? caseIdVar[0] : caseIdVar)

    mixpanel.track(MixpanelEvents.GwsPageLand, { pageLanded: 'Yes', page: currentPage() })

    /* Note:  We purposely leave eventId out of the dependency array
     * otherwise event is release immediately after it is held */
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useDebouncedEffect(
    () => {
      if (!eventId) {
        fetchAvailableDates()
      }
    },
    debounceTime,
    [eventId, branch, meetingMethodCategory, advisor]
  )

  useDebouncedEffect(
    () => {
      if (!eventId) {
        fetchAvailability()
      }
    },
    debounceTime,
    [advisor, eventId, date]
  )

  const handleSubmit = (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>): void => {
    holdTimeslot(
      branch?.transit!,
      date!,
      timeSlot?.time!,
      timeSlot?.type!,
      appointmentType!,
      serviceSubType!,
      proficiency!,
      meetingMethod,
      undefined,
      advisor?.email,
      bookingStore.origin
    )
      .then(response => {
        actions.setSubmitting(false)
        if (response.status === 201) {
          setBookingId(response.data.bookingId)
          mixpanel.track(MixpanelEvents.GwsPageNext, {
            province: province,
            existingAccount: convertBoolean(existingCustomer),
            appointmentMethod: meetingMethod,
            page: currentPage(),
            appointmentDate: moment.tz(date, ALBERTA_TIMEZONE).format('dddd'),
            teamMemberSelection: convertBoolean(advisor),
            appointmentTime: timeSlot ? timeRange(timeSlot.time!, timeSlot.type!) : 'not selected',
            lengthOfAppointment: ServiceSubTypes[serviceSubType!]?.meetingLength[appointmentType!],
            branchLocation: branch?.name,
            availableDatesCount: availableDates?.length,
            availableSlotsCount: availableSlots?.length,
            pageTime: secondsSinceTime(startTime.current)
          })
          navigate('/summary').then(() => {
            setAdvisor(response.data.advisor)
            setEventId(response.data.eventId)
          })
        } else if (response.status === 204) {
          fetchAvailability()
          actions.setSubmitting(false)
          notificationService.createNotification(
            'Cannot proceed. Looks like someone has just taken your selected time slot',
            UIState.Info
          )
        }
      })
      .catch(() => {
        notificationService.createNotification('Something went wrong', UIState.Error)
        actions.setSubmitting(false)
      })
  }

  return (
    <div data-testid={caseId ? `gws-page-${caseId}` : 'gws-page'}>
      <Feedback />
      <GwsDetailsForm
        bigTitle={i18n.__(copy.Book.BigTitle)}
        littleTitle={i18n.__(copy.Book.LittleTitle)}
        submitButtonText={i18n.__(copy.Buttons.Next)}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export const GwsPage = observer(BaseGwsPage)
